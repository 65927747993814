.cl_button__wrapper {
  border-radius: 4px;
  overflow: hidden;
}
.cl_button {
  min-height: 55px;
  text-align: center;
  padding: 18px 40px 18px 40px;
  text-transform: uppercase;
  background: transparent;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  font-family: "Syne";
  border: 2px solid transparent;
  border-image: linear-gradient(to right, #4E3EF4, #5DBDF8, #B1F6D5) 1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}
.cl_button__filled {
  border: none;
  background: linear-gradient(to right, #4E3EF4, #5DBDF8, #B1F6D5);
}
