::-webkit-scrollbar {
  display: none;
}
.modal-body::-webkit-scrollbar {
  display: block !important;
}

*{
  scroll-behavior: smooth;

}
body{
  font-family: 'Syne', sans-serif;
  color: #FFFFFF;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position-y: -114px;
  background-position-x: -2688px;
  background-size: 414%; */
  background-image: url(../public/img/background3.jpg);
  /* margin-top: -1px; */
}
a{  color: #FFFFFF }
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.table {
  font-family: "Gilroy-UltraLight";
  font-size: 18px;
}

.table td {
  padding: 8px 12px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.text__gradient {
  background: linear-gradient(to right, #4E3EF4, #5DBDF8, #B1F6D5);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-height: 750px) and (min-width: 1200px) {
  #main {
    padding-top: 100px;
  }

  #services, #contact {
    padding-bottom: 50px;
  }
}

@media (max-height: 500px) and (max-width: 1200px) {
  #main {
    padding-top: 50px;
  }

}
