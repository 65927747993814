.header__main {
  width: 100%;
  border-bottom: 1px solid rgba(79, 65, 245, 0.5);
  height: 100px;
}
.header__main_inner {
  display: flex;
  padding: 0 20px;
  flex-direction: row;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding-inline-start: 0;
    li {
      margin-right: 25px;
      margin-left: 25px;
    }
  }
  a {
    color: #ffffff;
    text-decoration: none;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
  }
}

.parallax_bg {
  margin-top: 100px;
}

.logo_container {
  padding: 0 10px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
