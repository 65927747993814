.footer__main {
  display: flex;
  //position: absolute;
  width: 100%;
  bottom: 0;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  border-top: 1px solid rgba(79, 65, 245, 0.5);
  height: 100px;
  ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding-inline-start: 0;
    li {
      margin-right: 20px;
      cursor: pointer;
    }
  }
  a {
    text-decoration: none;
    color: #B0BEC5;
    font-family: "Syne";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
  }
  p {
    color:#B0BEC5;
    text-align: start;
    font-family: "Gilroy-UltraLight";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
  // .logo_container {
  //   padding-left: 40px;
  //   padding-right: 40px;
  // }
  .footer__col{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  // :last-child {
  //   margin-right: 70px;
  // }
}

.parallax_bg {
  margin-top: 100px;
}

.footer__wrapper {
  position: relative;
  bottom: 0;
  width: 100%;
}
