.slide__main {
  min-height: 100vh;
  display: flex;
  flex-direction: row-reverse;

  .slide__left {
    align-self: center;
    flex: 1;
    position: relative;
    .exhange__container {
      // position: fixed;
      // bottom: 178px;
      // left: 70px;
    }

    .exchange_block {
      width: 378px;
      background: rgba(255, 255, 255, 0.1);
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
      backdrop-filter: blur(5px);
      border-radius: 10px;
      .exchange_info {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 24px 75px;
        margin-top: 15px;
        & span {
          font-family: "Gilroy-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 150%;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 6px;
        }
      }
      form {
        & .inputfield {
          display: flex;
          flex-direction: row;
          width: -webkit-fill-available;
          justify-content: space-between;
          height: 52px;
          background: rgba(255, 255, 255, 0.2);
          border: 1px solid rgba(255, 255, 255, 0.2);
          border-radius: 7px;
          font-family: "Gilroy-Regular";
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #ffffff;
          padding-left: 18px;
          padding-right: 18px;
          margin: 0;
          margin-bottom: 26px;
          .img-container {
            height: 100%;
            padding: 0 4px 0px 8px;
            display: flex;
            align-items: center;
          }
          select::-ms-expand {
            display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
          }
          .form-divider {
            align-self: center;
            // margin: 14px 0 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 50%;
            border-right: 1px solid rgba(255, 255, 255, 0.2);
          }
          input,
          select {
            outline: none;
            background: none;
            border: none;
            font-family: "Gilroy-Regular";
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: #ffffff;
            width: 50%;
            option {
              color: black;
            }
          }
          input {
            width: 33%;
            padding-top: 4px;
          }
          select: {
            width: 66%;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }
        }
        text-align: start;
        padding: 30px 64px;
        p {
          font-family: "Gilroy-Regular";
          font-style: normal;
          margin: 0;
          margin-bottom: 13px;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          &.exchange_hintText {
            margin: 0;
            margin-top: 28px;
            font-family: "Gilroy-UltraLight";
            text-align: center;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 15px;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }
        }
        input {
        }
      }
    }
  }
  .slide__right {
    align-self: center;
    flex: 1.5;
  }
  ._map-container {
    width: 100%;
    height: 306px;
  }
  .slide__text_block {
    padding-right: 24px;
    text-align: start;
    p {
      font-family: "Gilroy-UltraLight";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      margin: 5px 0;
      b {
        font-family: "Gilroy-Regular";
      }
    }
    ul {
      margin: 0;
      // padding: 0;
      padding-inline-start: 2rem;
      li {
        margin: 0;
        font-family: "Gilroy-UltraLight";
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}

h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 64px;
}

h2 {
  font-family: "Gilroy-Regular";
  color: #7c4dff;
  font-weight: 400;
  font-size: 28px;
  line-height: 36px;
}
