/* lg-размер (<=1199px) */
@media (max-width: 1199px) {
  .container {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .header__main,
  footer {
    height: 55px !important;
    a,
    p {
      font-size: 8px !important;
      line-height: 8px !important;
    }
    img {
      width: 100px;
      height: 16px;
    }
    .cl_button {
      min-height: 30px;
      font-size: 8px;
      line-height: 8px;
      padding: 2px 6px 2px 6px;
    }
  }
  .slide__text_block {
    h2 {
      font-size: 24px !important;
    }
    h1 {
      font-size: 36px !important;
      line-height: 36px !important;
    }
    p {
      font-size: 18px !important;
      line-height: 18px !important;
    }
    li {
      font-size: 16px !important;
      line-height: 16px !important;
    }
  }
  .slide__left {
    // width: 256px !important;
    .exhange__container {
      .exchange_block {
        width: 256px !important;
        .inputfield {
          height: 36px !important;
        }
      }
      form {
        padding: 16px 32px !important;
      }
      .exchange_info {
        padding: 12px 36px !important;
      }
      .exchange_info-text {
        font-size: 12px !important;
        svg {
          width: 25px !important;
          height: 16px !important;
        }
      }
    }
  }
  .slide__main {
    // min-height: fit-content;
  }
  .App {
    // min-width: 1200px;
    // min-height: 100vh;
  }
  ._map-container {
    width: 90% !important;
    height: 150px !important;
  }
  /* CSS для ширины от 992px до 1199px */
}

/* md-размер (<=991px) */
@media (max-width: 767px) {
  /* CSS для ширины от 768px до 991px */
  .modal{
    h1{
      font-size: 24px !important;
    }
  }
.modal-title{
  font-size: 1rem !important;
}
.modal-body{
  p{
    font-size: 0.8rem !important;
  }
}
  .container {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .logo_container {
    display: none !important;
  }
  footer {
    height: 60px !important;
    line-height: 12px;
    ul {
      margin: 0 !important;
    }
  }
  .header__main,
  footer {
    p{
      text-align: center !important;
    }
    // height: 48px !important;
    li {
      margin-right: 10px !important;
      margin-left: 10px !important;
    }

    a {
      font-size: 8px !important;
      line-height: 8px !important;
    }
    .logo_container {
      padding-left: 10px;
      padding-right: 10px;
    }
    img {
      width: 100px;
      height: 16px;
    }
    .cl_button {
      margin-right: 16px !important;
      min-height: 30px;
      font-size: 8px;
      line-height: 8px;
      padding: 2px 6px 2px 6px;
    }
  }
  .slide__right {
    display: flex !important;
    max-height: 100% !important;
    align-items: center !important;
    // max-width: 256px;
  }
  .slide__text_block {
    h2 {
      font-size: 18px !important;
    }
    h1 {
      font-size: 24px !important;
      line-height: 24px !important;
    }
    p {
      font-size: 16px !important;
      line-height: 16px !important;
    }
    li {
      font-size: 14px !important;
      line-height: 14px !important;
    }
  }
  .slide__left {
    // width: 256px !important;
    .exhange__container {
      margin-top: 20px;
      .exchange_block {
        width: 256px !important;
        .inputfield {
          padding-left: 8px !important;
          padding-right: 8px !important;
          // width: 60% !important;
          height: 24px !important;
          .img-container {
            img {
              width: 12px;
              height: 12px;
            }
          }
          input {
            padding-top: 0;
          }
        }
      }
      form {
        .form-divider {
          height: 100% !important;
          margin: 0 !important;
        }
        p {
          font-size: 12px !important;
        }
        padding: 8px 16px !important;
        input,
        select {
          font-size: 12px !important;
          line-height: 12px !important;
        }
        button {
          min-height: 24px;
          text-align: center;
          padding: 9px 20px;
          font-size: 12px;
        }
      }
      .exchange_info {
        padding: 12px 18px !important;
      }
      .exchange_info-text {
        font-size: 12px !important;
        svg {
          width: 25px !important;
          height: 16px !important;
        }
      }
    }
  }
  .slide__main {
    display: flex !important;
    flex-direction: column-reverse !important;
    justify-content: flex-end !important;
    &.fullscreen-slide {
      .slide__left {
        display: none;
      }
    }
  }

  .App {
    // min-width: 1200px;
    // min-height: 100vh;
  }
  /* CSS для ширины от 992px до 1199px */
  .init__exchange-form {
    max-height: 50% !important;
  }
}

/* sm-размер (<=768px) */
@media (max-width: 767px) {
  /* CSS для ширины от 576px до 767px */
}

/* xs-размер (<=575px) */
@media (max-width: 575px) {
  /* CSS для ширины до 575px (включительно) */
}
