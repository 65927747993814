.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.55);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1{
    margin: 0;
    font-size: 36px;
  }
  .modal-content {
    background-color: white;
    height: 80%;
    width: 80%;
    background: rgba(0, 0, 0, 0.719);
    backdrop-filter: blur(5px);
    .modal-title {
      padding: 0 2.25rem;
      height: 5rem;
      max-height: 5rem;
      text-transform: uppercase;
      font-size: 1.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .modal-body {
        text-align: start;
      height: 80%;
      overflow-y: scroll;
      padding: 0 2rem;
    }

    .modal-footer {
      height: 5rem;
      max-height: 5rem;
      padding: 0 2.25rem;
    }
  }
}
